import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import Settings from '../settings';

@inject(EventAggregator, Settings)
export class WebSocketService{
    constructor(ea, settings){
        this.ea = ea;
        this.settings = settings;
        this.socket = undefined;
        this.address = "ws://localhost:5600";
        this.setAddress();
        this.organization = "pelastusopisto"
    }
    setAddress(){
        console.log(this.settings.options);
        if(this.settings.options.address){
            if(this.settings.options.address.includes("https")){
                this.address = this.settings.options.address.replace("https", "wss");
            }else if(this.settings.options.address.includes("http")){
                this.address = this.settings.options.address.replace("http", "ws");
            }else{

            }
        }
    }
    connect(id){
        if(!id){
            return;
        }
        this.setAddress();
        this.socket = new WebSocket(this.address+"/"+this.organization+"/test/ws/"+id);
        this.socket.addEventListener("open", (event)=>{
        });
        this.socket.addEventListener("message", (event)=>{
            let result = JSON.parse(event.data);
            //Tätä vaan käytetään, ei lähetetä mitään.
            //this.ea.publish("test-data", event.data);
            this.ea.publish("test-data-"+result.id, result);
        });
        this.socket.addEventListener("close", (event)=>{
            console.log("Yhteys suljettu!");
        });
    }
    disconnect(){
        if(this.socket && this.socket.close){
            this.socket.close();
        }
    }
}
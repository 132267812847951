export default class Settings{
    constructor(){
        this.options = {
            address: 'http://localhost:5600'
        };
        this.load();
    }
    load(){
        try{
        this.options = JSON.parse(sessionStorage.getItem('settings'));
        if(this.options == null){
            this.options = {

            };
        }
        }catch(e){

        }
    }
    save(){
        sessionStorage.setItem('settings', JSON.stringify(this.options));
    }
    logout(){
        this.options.code = undefined;
        this.save();
    }
}
import {EventAggregator} from "aurelia-event-aggregator";
import {inject} from "aurelia-framework";
import Settings from '../settings';

@inject(EventAggregator, Settings)
export class ApiService{
    constructor(ea, settings){
        this.ea = ea;
        this.url = "http://localhost:5600";
        this.organization = "pelastusopisto";
        this.configured = false;
        this.settings = settings;
        if(this.settings.options.address){
            this.url = this.settings.options.address;
        }
        fetch("/config.json").then(response => response.json()).then((res)=>{
            if(res && res.address){
                this.url = res.address;
                this.settings.options.address = res.address;
                this.settings.save();
            }
        });
    }
    login(username, password){
        return fetch(this.url+"/access", {method: "POST", body:JSON.stringify({username: username, password: password}), headers:{'Content-Type':'application/json'}}).then(response => response.json()).then((res)=>{
            return res;
        });
    }
    logout(){

    }
    getTests(){
        return fetch(this.url+"/"+this.organization+"/tests", {headers:{code: this.settings.options.code}}).then(response => response.json()).then((res)=>{
            return res;
        });
    }
    addTest(test){
        return fetch(this.url+"/"+this.organization+"/tests", {
            method:"POST",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(test)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    startTest(){

    }
    endTest(){
        
    }
    editTest(test){
        return fetch(this.url+"/"+this.organization+"/tests", {
            method:"PUT",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(test)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    removeTest(test){
        return fetch(this.url+"/"+this.organization+"/tests", {
            method:"DELETE",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                 code: this.settings.options.code
            },
            body: JSON.stringify(test)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    getTestPersons(test){
        return fetch(this.url+"/"+this.organization+"/test/persons",{
            method:"POST",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                 code: this.settings.options.code
            },
            body: JSON.stringify(test)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    getPersons(){
        return fetch(this.url+"/"+this.organization+"/persons", {headers:{'Content-Type':'application/json', code: this.settings.options.code}}).then(response => response.json()).then((res)=>{
            return res;
        });
    }
    addPerson(user){
        return fetch(this.url+"/"+this.organization+"/persons", {
            method:"POST",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(user)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    editPerson(user){
        return fetch(this.url+"/"+this.organization+"/persons", {
            method:"PUT",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(user)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    removePerson(user){
        return fetch(this.url+"/"+this.organization+"/persons", {
            method:"DELETE",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(user)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    activateTest(test){
        return fetch(this.url+"/"+this.organization+"/test/activate", {
            method:"POST",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(test)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    deactivateTest(test){
        return fetch(this.url+"/"+this.organization+"/test/de-activate", {
            method:"POST",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(test)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    removeTest(test){
        return fetch(this.url+"/"+this.organization+"/tests", {
            method:"DELETE",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(test)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    getMeasurements(testid, personid){
        return fetch(this.url+"/"+this.organization+"/person/measurements/"+testid, {
            method:"POST",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify({id: personid})
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    getMoreMeasurements(testid, personid, limit, skip){
        return fetch(this.url+"/"+this.organization+"/person/measurements/"+testid+"?limit="+limit+"&skip="+skip, {
            method:"POST",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify({id: personid})
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    setVariableParameters(data){
        return fetch(this.url+"/"+this.organization+"/test/variable-paramters", {
            method:"PUT",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            },
            body: JSON.stringify(data)
        }).then(rep => rep.json()).then((res)=>{
            return res;
        });
    }
    getDynamicParameters(testid, personid){
        return fetch(this.url+"/"+this.organization+"/dynamic-parameters/"+testid+"/"+personid+".json", {
            method:"GET",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            }
        }).then((rep)=>rep.json()).then((res)=>{
            let newTab = window.open('', '_blank');
            newTab.document.write('<html><head><title>JSON Viewer</title></head><body><pre>' + JSON.stringify(res, null, 2) + '</pre></body></html>');

            return;
        });
    }
    getCalculations(testid, personid){
        return fetch(this.url+"/"+this.organization+"/calculations/"+testid+"/"+personid+".json", {
            method:"GET",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            }
        }).then((rep)=>rep.json()).then((res)=>{
            let newTab = window.open('', '_blank');
            newTab.document.write('<html><head><title>JSON Viewer</title></head><body><pre>' + JSON.stringify(res, null, 2) + '</pre></body></html>');

            return;
        });
    }
    restartApp(){
        return fetch(this.url+"/"+this.organization+"/restart-app", {
            method:"GET",
            mode:"cors",
            headers:{
                "Content-Type":"application/json",
                code: this.settings.options.code
            }
        }).then(()=>{
            return true;
        }).catch(()=>{
            return false;
        });
    }
}